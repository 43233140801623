.ant-collapse-content > .ant-collapse-content-box{padding:0px}
.collapse-setting .ant-collapse-header{padding: 12px 16px; background-color: #fafafa; border:1px solid #d9d9d9; margin-bottom: 10px; border-radius: 5px;}
.collapse-setting {border-bottom: 0px!important; margin-bottom: 30px;}
.layout-content .ant-collapse-header{background: none; padding-left:0px; padding-right: 0px}
.layout-content .header{display: flex; flex-direction: row; align-items: center; padding:0px}
.layout-content .header .title{font-size: 22px; color: var(--c-primary); margin-left: 20px; font-weight: 500; text-transform: capitalize;}
.layout-content .ant-collapse{background: transparent;}
.layout-content .item-doctor{margin-bottom: 30px;}
.layout-content .item-doctor .box-cover{ width: 100%; height: 180px; overflow: hidden; box-shadow: 1px 2px 4px 1px #ccc;}
.layout-content .item-doctor .box-cover img{width: 100%; min-height: 180px;}
.layout-content .list-doctor .label{font-size: 20px; font-weight: 700; margin: 30px 0px 20px 0px}
.layout-content .doctor-name{font-size: 18px; font-weight: 500;}
.layout-content .position{color: #777; font-size: 16px; margin: 10px 0px 20px 0px;}
.layout-content .sapo{color: #555; font-size: 14px;margin-top:10px;margin-bottom: 10px;}

.order-tab .ant-tabs-nav::before{border-color: #ddd;}
.order-tab .ant-tabs-tab-active{background: transparent!important;}
.order-tab .ant-tabs-tab{border-color: #ddd!important;}
.order-tab .ant-tabs-tab.ant-tabs-tab-active{border-bottom: 1px solid #F0F2F5!important;}