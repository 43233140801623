body, html {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.bg-login{
	background-image: url('../../assets/images/bg-login.svg');
	background-repeat: no-repeat;
	background-position: center 110px;
	background-size: 100%;
	background-color: #f0f2f5;
	min-height: 100vh;
}
.login .logo{margin: 30px 0px}
.login .logo img{height: 70px; margin:0px}
.login .slogan {
	font-size: 14px;
	color: rgba(0,0,0,.45);
	margin-top: 12px;
	margin-bottom: 20px; text-align: center;
}
.login-form-button {
	width: 100%;
	height: 40px;
	border-radius: 5px; margin-top:20px
}
.login .form-control{line-height: 30px; border-radius: 5px; margin-top:10px}