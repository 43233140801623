.ant-collapse-content > .ant-collapse-content-box{padding:0px}
.collapse-setting .ant-collapse-header{padding: 12px 16px; background-color: #fafafa; border:1px solid #d9d9d9; margin-bottom: 10px; border-radius: 5px;}
.collapse-setting {border-bottom: 0px!important; margin-bottom: 30px;}

.item-doctor-order{font-size: 14px; margin-bottom: 16px; background: #fff; border-radius: 5px; padding:5px 0px}
.item-doctor-order .code{font-weight: 700; text-align: center; font-size: 11px;}
.item-doctor-order .icon{font-size: 13px; margin-right: 5px; color: #999;}
.item-doctor-order .col-patient{padding-top: 4px; padding-left:16px}
.item-doctor-order .col-patient .time{font-weight: 700; padding-right: 5px; color:#0088ca}
.item-doctor-order .col-patient .name{font-weight: 700; padding-right: 5px; color:#0088ca}
.item-doctor-order .col-patient .other{font-size: 11px; color: #999; font-weight: 500;}
.item-doctor-order .col-detail .name{font-weight: 500; padding-right:16px}
.item-doctor-order .phone{font-weight: 500; padding-right: 16px; color:#0088ca}
.item-doctor-order .col-detail .cate{font-weight: 500;}
.item-doctor-order .col-detail .note{color: #00000073; margin: 5px 0px 8px 0px; font-size: 13px; line-height: 20px; font-style: italic;}
.item-doctor-order .col-detail .group-btn{margin-bottom: 5px;}
.item-doctor-order .col-detail .group-btn button{margin-right: 8px; font-size: 13px;}
.item-doctor-order .col-detail .group-btn button .icon{font-size: 11px; color:#fff}
.popover-video{width: 320px;}
.popover-video .group-btn{display: flex; flex-direction: row; justify-content: space-between;}
.popover-video .group-btn button{width: 48%; font-size: 13px;}
.popover-video .ant-alert{margin-bottom: 12px;}
.item-doctor-order .btn-status{color:#fff; font-size: 10px;}