body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.body-height{min-height: 100vh;}
.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1890ff;
}
.logo img {
  height: 50px; margin: 8px 20px; transition: all ease-in-out 0.15s;
}
.ant-layout-sider{z-index: 10;}
.ant-layout-sider-collapsed .logo img{height: 27px; margin:17px 10px}
.site-layout .site-layout-background {
  background: #fff; display: flex; flex-direction: row;
}
.header-menu-right{margin-left: auto; margin-right: 15px;}
.user-drop{min-width: 120px!important;}
.layout-content{padding: 24px; min-height: 280px; margin:0px; background: #fff;}

/* customer css table */
.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
	background: #eee;
}

.footer-toolbar{
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  width: 100%;
  height: 56px;
  padding: 0 24px;
  line-height: 56px;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  box-shadow: 0 -1px 2px rgb(0 0 0 / 3%);
  transition: all .3s;
}
.footer-toolbar .right{margin-left: auto;}

.drop-select{z-index: 9999;}

.border{border: 1px solid #e5e5e5}
.text-center{text-align: center;}
